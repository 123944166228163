import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'

window._ = require('lodash');

global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;


import * as bootstrap from "bootstrap/dist/js/bootstrap.esm";
Vue.prototype.$bootstrap = bootstrap

// import 'bootstrap/dist/css/bootstrap.min.css';
import "@/assets/styles/app.scss";


import moment from "moment";
window.moment = moment;
require('moment-timezone');
moment.locale('en-GB');
import VueClipboard from 'vue-clipboard2';

Vue.use(VueClipboard);

/*
vue-json-csv : https://github.com/Belphemur/vue-json-csv
*/

var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo)

import wysiwyg from "vue-wysiwyg";
Vue.use(wysiwyg, {
    hideModules: {
        "table": true
    },
});



Vue.config.productionTip = false
import axios from "axios"
Vue.prototype.$axios = axios

Vue.prototype.$axios.defaults.headers.common['Content-Type'] = 'application/json'
Vue.prototype.$axios.defaults.headers.common['Accept'] = 'application/json'

// require("./validationRules");


// Validation Rules

import {
    extend
} from 'vee-validate';
import {
    required,
    email,
    min,
    url
} from 'vee-validate/dist/rules';

import PhoneNumber from 'awesome-phonenumber'

extend('phone', {
    message(fieldName) {
        return `Please enter a valid phone number`;
    },
    validate(value) {
        return new Promise(resolve => {
            let phone = new PhoneNumber(value);
            resolve({
                valid: phone.isValid()
            })
        });
    }
});

// Add the required rule
extend('required', {
    ...required,
    message: 'This field is required'
});

extend('email', {
    ...email,
    message: 'Please provide a valid email address'
});

extend('min', {
    ...min,
    message: 'Input not long enough'
});

import isURL from 'validator/lib/isURL';

extend('url', {
    message(fieldName) {
        return `The URL is not valid eg. https://swandoola.com`;
    },
    validate(value) {
        return isURL(value, {
            require_tld: true
        });
    }
});

extend('email_unique', {
    message(fieldName) {
        return `Email address already in use.`;
    },
    async validate(value) {
        const response = await fetch('/clients/check-email/' + value);
        const responseJson = await response.json();
        return responseJson.valid === "true";
    }
});

import {
    ValidationProvider
} from 'vee-validate';

Vue.component('ValidationProvider', ValidationProvider);

// End validation rules

import VueSnackbar from 'vue-snack'
import 'vue-snack/dist/vue-snack.min.css'


import VTooltip from 'v-tooltip'
Vue.use(VTooltip)

Vue.use(VueSnackbar, {
    position: 'bottom-right',
    time: 3000
})


import EventBus from "./eventBus";
Vue.prototype.$EventBus = EventBus;

Vue.component('text-editor-modal', require('./views/globals/TextEditorModal.vue').default);
Vue.component('view-document-modal', require('./views/globals/ViewDocumentModal.vue').default);
Vue.component('phone-number-input', require('./views/globals/PhoneNumberInput.vue').default);
Vue.component('date-input', require('./views/globals/DateInput.vue').default);
Vue.component('alerts', require('./views/layouts/Alerts.vue').default);

import Vue2Editor from "vue2-editor";

Vue.use(Vue2Editor);

import VueStash from 'vue-stash';
import store from './store/store'

Vue.use(VueStash)

import permissions from './mixins/permissions';
Vue.mixin(permissions);


// Service worker for self-updating cache
import './registerServiceWorker';

Vue.filter('formatDate', (date) => {
    return moment(date).format('LL');
})

Vue.filter('formatDateTime', (date) => {
    return moment(date).format('LLL');
})

new Vue({
    router,
    data: {
        store
    },

    created() {

        const userInfo = localStorage.getItem('user')
        if (userInfo) {
            const userData = JSON.parse(userInfo)

            this.$store.user = userData.user;
            this.$store.token = userData.token;
            this.$store.impersonating = userData.impersonating;
            this.$store.impersonationToken = userData.impersonationToken;


            this.$axios.defaults.headers.Authorization = `Bearer ${this.$store.token.access_token}`;
            if (this.$store.impersonating) {
                this.$axios.defaults.headers.common['amchara-impersonation'] = [this.$store.impersonating.id, this.$store.user.id]
            }
        }

        this.$axios.interceptors.response.use(
            response => response,
            error => {
                if (error.response.status === 401 || error.response.status === 403) {
                    localStorage.removeItem('user')
                    // this.$store.dispatch('logout')

                    if (localStorage.getItem("googleLogin")) {
                        var auth2 = gapi.auth2.getAuthInstance();
                        auth2.signOut().then(function () {
                            console.log("User signed out.");
                        });
                    }
                    localStorage.removeItem("googleLogin");

                    location.href = '/';
                }
                return Promise.reject(error)
            }
        )
    },
    mounted() {

    },
    render: h => h(App)
}).$mount('#app')