import Vue from 'vue'
import VueRouter from 'vue-router'

import settings from "@/router/settings";

import VueMeta from 'vue-meta'


Vue.use(VueRouter)
Vue.use(VueMeta)

const routes = [
    //
    {
        path: "*",
        name: "NotFound",
        component: () =>
            import( /* webpackChunkName: "page-not-found" */ '../views/globals/PageNotFound.vue'),
        meta: {
            auth: false
        }
    },
    {
        path: '/',
        name: 'Home',
        component: () =>
            import( /* webpackChunkName: "home-page" */ '../views/pages/Home.vue')
    },
    {
        path: '/login',
        name: 'Login',
        component: () =>
            import( /* webpackChunkName: "login" */ '../views/auth/Login.vue')
    },
    {
        path: '/login/:token',
        name: 'LoginSplash',
        component: () =>
            import( /* webpackChunkName: "login-splash" */ '../views/auth/LoginSplash.vue')
    },

    //
    {
        path: '/dashboard',
        name: 'DashboardView',
        component: () =>
            import( /* webpackChunkName: "dashboard-view" */ '../views/areas/dashboard/Index.vue'),
        meta: {
            auth: true
        },
    },

    //

    {
        path: '/billing',
        name: 'BillingIndex',
        component: () =>
            import( /* webpackChunkName: "client-view" */ '../views/areas/billing/Index.vue'),
        meta: {
            auth: true
        },
    },

    //

    {
        path: '/clients',
        name: 'ClientsIndex',
        component: () =>
            import( /* webpackChunkName: "clients-index" */ '../views/areas/clients/Index.vue'),
        meta: {
            auth: true
        },
    },


    {
        path: '/clients/create',
        name: 'ClientCreate',
        component: () =>
            import( /* webpackChunkName: "client-create" */ '../views/areas/clients/Create.vue'),
        meta: {
            auth: true
        },
    },

    {
        path: '/clients/:id',
        name: 'ClientView',
        component: () =>
            import( /* webpackChunkName: "client-view" */ '../views/areas/clients/View.vue'),
        meta: {
            auth: true
        },
    },

    {
        path: '/clients/:id/billing/:bookingId',
        name: 'ClientBillingView',
        component: () =>
            import( /* webpackChunkName: "client-view" */ '../views/areas/clients/Billing.vue'),
        meta: {
            auth: true
        },
    },

    // Users

    {
        path: '/users',
        name: 'UsersIndex',
        component: () =>
            import( /* webpackChunkName: "users-index" */ '../views/areas/users/Index.vue'),
        meta: {
            auth: true
        },
    },


    {
        path: '/users/:id',
        name: 'UsersView',
        component: () =>
            import( /* webpackChunkName: "users-view" */ '../views/areas/users/View.vue'),
        meta: {
            auth: true
        },
    },

    // Posts
    {
        path: '/posts',
        name: 'PostsIndex',
        component: () =>
            import( /* webpackChunkName: "posts-index" */ '../views/areas/posts/Index.vue'),
        meta: {
            auth: true
        },
    },


    {
        path: '/posts/create',
        name: 'PostsCreate',
        component: () =>
            import( /* webpackChunkName: "posts-create" */ '../views/areas/posts/Create.vue'),
        meta: {
            auth: true
        },
    },



    {
        path: '/posts/:id',
        name: 'PostsView',
        component: () =>
            import( /* webpackChunkName: "posts-view" */ '../views/areas/posts/View.vue'),
        meta: {
            auth: true
        },
    },

    // Enquiries
    {
        path: '/enquiries',
        name: 'EnquiriesIndex',
        component: () =>
            import( /* webpackChunkName: "enquiries-index" */ '../views/areas/enquiries/Index.vue'),
        meta: {
            auth: true
        },
    },

    // Reporting
    {
        path: '/reporting',
        name: 'ReportingIndex',
        component: () =>
            import( /* webpackChunkName: "reporting-index" */ '../views/areas/reporting/Index.vue'),
        meta: {
            auth: true
        },
    },

    {
        path: '/reporting/retreat-bookings',
        name: 'ReportingRetreatBookings',
        component: () =>
            import( /* webpackChunkName: "reporting-retreat-bookings" */ '../views/areas/reporting/RetreatBookings.vue'),
        meta: {
            auth: true
        },
    },


    {
        path: '/reporting/accounts-by-date',
        name: 'ReportingAccountsByDate',
        component: () =>
            import( /* webpackChunkName: "reporting-accounts-by-date" */ '../views/areas/reporting/AccountsByDate.vue'),
        meta: {
            auth: true
        },
    },

    {
        path: '/reporting/stripe-booking-payments',
        name: 'Stripe Booking Payments',
        component: () =>
            import( /* webpackChunkName: "reporting-stripe-booking-payments" */ '../views/areas/reporting/StripeBookingPayments.vue'),
        meta: {
            auth: true
        },
    },

    {
        path: '/reporting/debtors',
        name: 'Debtors',
        component: () =>
            import( /* webpackChunkName: "debtors" */ '../views/areas/reporting/Debtors.vue'),
        meta: {
            auth: true
        },
    },

    {
        path: '/reporting/enquiries-by-traffic-source',
        name: 'EnquiriesByTrafficSource',
        component: () =>
            import( /* webpackChunkName: "reporting-enquiries-by-traffic-source" */ '../views/areas/reporting/EnquiriesByTrafficSource.vue'),
        meta: {
            auth: true
        },
    },

    {
        path: '/reporting/enquiries-by-traffic-source-new',
        name: 'EnquiriesByTrafficSourceNew',
        component: () =>
            import( /* webpackChunkName: "reporting-enquiries-by-traffic-source-new" */ '../views/areas/reporting/EnquiriesByTrafficSourceNew.vue'),
        meta: {
            auth: true
        },
    },

    // Bookings
    {
        path: '/bookings/calendar',
        name: 'BookingsCalendar',
        component: () =>
            import( /* webpackChunkName: "bookings-calendar" */ '../views/areas/bookings/Calendar.vue'),
        meta: {
            auth: true
        },
    },

    {
        path: '/bookings/due-ins',
        name: 'BookingsDueIns',
        component: () =>
            import( /* webpackChunkName: "bookings-due-ins" */ '../views/areas/bookings/DueIns.vue'),
        meta: {
            auth: true
        },
    },

    {
        path: '/bookings/dueins-export',
        name: 'BookingsDueInsExport',
        component: () =>
            import( /* webpackChunkName: "bookings-dueins-export" */ '../views/areas/bookings/DueInsExport.vue'),
        meta: {
            auth: true
        },
    },

    {
        path: '/bookings/housekeeping',
        name: 'BookingsHousekeeping',
        component: () =>
            import( /* webpackChunkName: "bookings-housekeeping" */ '../views/areas/bookings/Housekeeping.vue'),
        meta: {
            auth: true
        },
    },


    {
        path: '/bookings/create',
        name: 'BookingsCreate',
        component: () =>
            import( /* webpackChunkName: "bookings-create" */ '../views/areas/bookings/Create.vue'),
        meta: {
            auth: true
        },
    },


    // Kitchen
    {
        path: '/kitchen/board',
        name: 'KitchenBoard',
        component: () =>
            import( /* webpackChunkName: "kitchen-board" */ '../views/areas/kitchen/Board.vue'),
        meta: {
            auth: true
        },
    },

    {
        path: '/kitchen/allergies',
        name: 'KitchenAllergies',
        component: () =>
            import( /* webpackChunkName: "kitchen-allergies" */ '../views/areas/kitchen/Allergies.vue'),
        meta: {
            auth: true
        },
    },

    {
        path: '/kitchen/supplements',
        name: 'KitchenSupplements',
        component: () =>
            import( /* webpackChunkName: "kitchen-supplements" */ '../views/areas/kitchen/Supplements.vue'),
        meta: {
            auth: true
        },
    },



    // Sms
    {


        path: '/sms',
        name: 'SmsIndex',
        component: () =>
            import( /* webpackChunkName: "sms-index" */ '../views/areas/sms/Index.vue'),
        meta: {
            auth: true
        },
    },


    // Talks
    {


        path: '/talks',
        name: 'TalksIndex',
        component: () =>
            import( /* webpackChunkName: "talks-index" */ '../views/areas/talks/Index.vue'),
        meta: {
            auth: true
        },
    },

    {


        path: '/talks/:id',
        name: 'TalksView',
        component: () =>
            import( /* webpackChunkName: "talks-view" */ '../views/areas/talks/View.vue'),
        meta: {
            auth: true
        },
    },


    // Sms
    {


        path: '/forms/requiring-attention',
        name: 'FormsRequiringAttention',
        component: () =>
            import( /* webpackChunkName: "forms-requiring-attention" */ '../views/areas/forms/RequiringAttention.vue'),
        meta: {
            auth: true
        },
    },

    // Emails
    {


        path: '/emails/change',
        name: 'ChangeEmailsIndex',
        component: () =>
            import( /* webpackChunkName: "emails-index" */ '../views/areas/emails/ChangeIndex.vue'),
        meta: {
            auth: true
        },
    },

    {


        path: '/emails/change/:id',
        name: 'EmailView',
        component: () =>
            import( /* webpackChunkName: "emails-view" */ '../views/areas/emails/View.vue'),
        meta: {
            auth: true
        },
    },


    // Roles
    {


        path: '/roles',
        name: 'RolesIndex',
        component: () =>
            import( /* webpackChunkName: "roles-index" */ '../views/areas/roles/Index.vue'),
        meta: {
            auth: true
        },
    },



    {
        path: '/roles/create',
        name: 'RolesCreate',
        component: () =>
            import( /* webpackChunkName: "roles-create" */ '../views/areas/roles/Create.vue'),
        meta: {
            auth: true
        },
    },

    // Email Templates
    {


        path: '/email-templates',
        name: 'EmailTemplatesIndex',
        component: () =>
            import( /* webpackChunkName: "email-templates-index" */ '../views/areas/email-templates/Index.vue'),
        meta: {
            auth: true
        },
    },

    {


        path: '/email-templates/:id',
        name: 'EmailTemplatesView',
        component: () =>
            import( /* webpackChunkName: "email-templates-view" */ '../views/areas/email-templates/View.vue'),
        meta: {
            auth: true
        },
    },

    // Forms
    {


        path: '/form-templates',
        name: 'FormsIndex',
        component: () =>
            import( /* webpackChunkName: "forms-index" */ '../views/areas/form-templates/Index.vue'),
        meta: {
            auth: true
        },
    },

    {


        path: '/form-templates/new',
        name: 'FormsNew',
        component: () =>
            import( /* webpackChunkName: "form-templates-new" */ '../views/areas/form-templates/New.vue'),
        meta: {
            auth: true
        },
    },

    {


        path: '/form-templates/:id',
        name: 'FormsView',
        component: () =>
            import( /* webpackChunkName: "form-templates-view" */ '../views/areas/form-templates/Builder.vue'),
        meta: {
            auth: true
        },
    },

    {


        path: '/xero/oauth',
        name: 'XeroOauth',
        component: () =>
            import( /* webpackChunkName: "xero-oauth" */ '../views/areas/xero/Oauth.vue'),
        meta: {
            auth: true
        },
    },

    {


        path: '/xero/redirect',
        name: 'XeroRedirect',
        component: () =>
            import( /* webpackChunkName: "xero-redirect" */ '../views/areas/xero/Redirect.vue'),
        meta: {
            auth: true
        },
    },



    {


        path: '/treatments',
        name: 'TreatmentsIndex',
        component: () =>
            import( /* webpackChunkName: "treatments-index" */ '../views/areas/treatments/Index.vue'),
        meta: {
            auth: true
        },
    },

    {
        path: '/treatments/create',
        name: 'TreatmentsCreate',
        component: () =>
            import( /* webpackChunkName: "treatments-create" */ '../views/areas/treatments/Create.vue'),
        meta: {
            auth: true
        },
    },

    {
        path: '/treatments/:id',
        name: 'TreatmentsView',
        component: () =>
            import( /* webpackChunkName: "posts-view" */ '../views/areas/treatments/View.vue'),
        meta: {
            auth: true
        },
    },


]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    linkActiveClass: "text-dark",
    linkExactActiveClass: "text-dark",
})

router.beforeEach((to, from, next) => {
    const loggedIn = localStorage.getItem('user')
    if (to.matched.some(record => record.meta.auth) && !loggedIn) {
        next('/login')
        return
    }

    if (to.path == '/login' || to.path == '/') {
        if (loggedIn) {
            next('/dashboard')
        }
    }
    next()

})

export default router